import React from "react";
import { Link } from "react-router-dom";
const OurSecurity = () => {const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
  return (
    <div>
      <section className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 mb-5">
              <div className="card custom-card">
                <div className="card-body text-center">
                  <h3 className="card-title text-blue mb-0">
                    <Link to="/application-security" onClick={scrollToTop}>
                      Application Security
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 mb-5">
              <div className="card custom-card">
                <div className="card-body text-center">
                  <h5 className="card-title text-blue mb-0">
                    <Link to="/cloud-security">Cloud Security</Link>
                  </h5>
                </div>
              </div>
            </div> */}
            <div className="col-lg-6 col-md-6 mb-5">
              <div className="card custom-card">
                <div className="card-body text-center">
                  <h3 className="card-title text-blue mb-0">
                    <Link to="/regulatory-compliance" onClick={scrollToTop}>
                      GRC
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 mb-5">
              <div className="card custom-card">
                <div className="card-body text-center">
                  <h5 className="card-title text-blue mb-0">
                    <Link to="/risk-assessment"> TPRM </Link>
                  </h5>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurSecurity;
