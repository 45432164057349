import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const BlogCategory = () => {

  //get category
  const state = useSelector(state => state.Allcategory)
  const { category } = state

  //filtered category (only active categories can be seen on frontend)
  const filteredCategory = category.filter((cat) => cat.isActive);



  const navigate = useNavigate()

  return (
    <div className="row">
      <div className="col-lg-12">
        <div>
          <div className="card-categories">
            <h3 className="text-blue">Categories</h3>
            <ul className="card-categories-list">
              {filteredCategory.map((category) => (
                <li
                  key={category}
                  onClick={() => navigate(`/blog?filter=${category._id}`)}
                >
                  <span className="link cursor-pointer">{category.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCategory
