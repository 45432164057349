import React from 'react'
import { Link } from "react-router-dom";

const BlogCard = ({ post }) => {
  const current = new Date();
  //eslint-disable-next-line
  const date = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;
  return (
    <div>
      <Link className="dark-text" to={`/single-blog/${post.slug}`}>
        <div className="card blog-card">
          <img className="card-img-top" src={`${process.env.REACT_APP_API_BASE_URL}/${post.photo}`} alt="" />
          <div className="card-body">
            <div className="card-text card-text-category">
              <small className="uppercase">
                <a className="dark-text link" href="/blog">
                  {post.title}
                </a>
                ,
                <a className="dark-text link" href="/blog">
                  {post.category.name}
                </a>
              </small>
            </div>
            <h4 className="bold-lg card-title mb-4">
              {post.snippet}
            </h4>
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-center">
                <div >
                  <p>
                    <small>{new Date(post.createdAt).toDateString()}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default BlogCard