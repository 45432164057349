import React from 'react'
import Application from '../../view/application/index'
const index = () => {
  return (
    <div>
        <Application/>
    </div>
  )
}

export default index