import React from "react";
import DataClassification from "../../assets/data-classifcation.png";
import ControlGap from "../../assets/control-gap.png";
import ReducedCost from "../../assets/reduced-cost.png";

const ServicesSection = () => {
  return (
    <div>
      <section className="security-section grc-service">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-4 mb-4">
              <div className="card service-card">
                <div className="card-body">
                  <div className="icon-img mb-4 ">
                    <img alt="" src={DataClassification} />
                  </div>
                  <h5 className="card-title warn-text uppercase">GOVERNANCE</h5>
                  <p className="card-text">
                    We have experience in setting up the policies and related
                    processes that presents transparent management view for
                    governance at reduced cost for all the software applications
                    under the enterprise being developed.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card service-card">
                <div className="card-body">
                  <div className="icon-img mb-4 ">
                    <img alt="" src={ControlGap} />
                  </div>
                  <h5 className="card-title warn-text uppercase">RISK</h5>
                  <p className="card-text">
                    Due to various reasons as not scanning the software
                    application during production, software applications might
                    have vulnerabilities that could be exploited. These
                    vulnerabilities ultimately manifest in as risk to the
                    business. The early these risks are identified, analyzed,
                    prioritized, and presented to the management with cost to
                    fix for their decision saves time, money and reputation for
                    the company. Our team has more than 15 years of experience
                    in helping companies in mitigating risk with minimal
                    spending.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card service-card">
                <div className="card-body">
                  <div className="icon-img mb-4 ">
                    <img alt="" src={ReducedCost} />
                  </div>
                  <h5 className="card-title warn-text uppercase">
                  COMPLIANCE
                  </h5>
                  <p className="card-text">
                    Almost all businesses have customers spread across different
                    regions of the world. Each of these regions has different
                    privacy laws. For example, European Union has GDPR a data
                    protection as well as privacy law. This law is different
                    than any privacy law in USA. Any business application,
                    irrespective or its hosting, dealing with EU customer has to
                    comply with GDPR law. Our team has skills to help enterprise
                    to have a standard across the board to help development
                    teams comply with various laws during development. Our team
                    also has skills to respond to MRAs and audits and help
                    companies with solutions that are easy and cheap and
                    implement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesSection;
