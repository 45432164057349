import {
    ALL_CATEGORY_REQUEST,
    ALL_CATEGORY_SUCCESS,
    ALL_CATEGORY_FAIL,
} from "../constants/categoryContant";
import axios from "axios";

 // Get all category
export const categoryListAction = (limit) => async (dispatch)=>  {
  try{
    dispatch({ type: ALL_CATEGORY_REQUEST });
    const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/category/getcategory?limit=${limit}`);
    dispatch({ 
        type: ALL_CATEGORY_SUCCESS, 
        payload: data, 
      });
  }catch(error){
     dispatch({
      type: ALL_CATEGORY_FAIL,
      payload: error.response.data.message,
     });
  }
};

