import React from 'react'
import Home from '../../view/home/index'
// import { Head } from "react-router-dom";
const index = () => {
  return (
    <div>
        <Home/>
    </div>
  )
}

export default index