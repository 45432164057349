import React from 'react'
import Career from '../../view/career/index'
const index = () => {
  return (
    <div>
        <Career/>
    </div>
  )
}

export default index