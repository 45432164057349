import React from "react";
import RegulatoryImg from "../../assets/regulatory-compliance.png";
const TopSection = () => {
  return (
    <div>
      <section className="pt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-9">
              <div className="hero-text pt-5">
                <h1 className="text-blue mb-5 line-ht">
                  GRC (Governance Risk and Compliance)
                </h1>
                <p className="para-text mb-5 line-ht">
                  We adopt a simple and yet technically sound approach to answer
                  audit or MRA findings. Our simple solution looks at below
                  three things and takes a very unique approach to answer the
                  findings.
                  <br />
                  <div className="pl-4 mt-3">
                    <p className="mb-0">
                      1)&nbsp; Understand the data and it’s processing system.
                    </p>
                    <p className="mb-0">
                      2)&nbsp; Understand the identified control gaps in the
                      audit or MRA report.
                    </p>
                    <p className="mb-0">
                      3)&nbsp; Analyze the existing control in unique way to
                      fix the gap in minimum time and cost.
                    </p>
                  </div>
                </p>
                {/* <p className="link">
                  <a className="btn" href="/">
                    <span className="btn-index"> Read More</span>
                  </a>
                </p> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-9">
              <div className="hero-img-top text-center pt-5">
                <img alt="" src={RegulatoryImg} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TopSection;
