import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogCategory from "../blog/BlogCategory";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import Loader from "../loading/Loader";

const BlogStructure = () => {
  const [Post, setPost] = useState()
  console.log(Post, "post")
  const [loading, setLoading] = useState(false)

  const current = new Date();
  //eslint-disable-next-line
  const date = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;

  const { slug } = useParams()

  const dirtyHTML = Post && Post.description;

  const cleanHTML = DOMPurify.sanitize(dirtyHTML, {
    USE_PROFILES: { html: true },
  });

  //single post by slug
  const getPost = async (slug) => {
    try {
      setLoading(true)
      const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/post/getsinglepost/${slug}`);
      setPost(data.UserPost);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getPost(slug)
  }, [slug])


  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>

          <div className="blog-content pt-80 pb-5">
            <div className="container">
              <div className="row flex-direction">
                <div className="col-lg-9">
                  <div className="single-img mb-2">
                    {Post?.photo && (<img className="card-img-top" src={`${process.env.REACT_APP_API_BASE_URL}/${Post?.photo}`} alt="" />)}
                  </div>
                  <div className="d-flex justify-content-between">
                    <ul className="d-flex text-right mb-0">
                      <li>
                        <span className="mb-0 d-inline ">
                          <small> {new Date(Post && Post.createdAt).toDateString()}</small>
                        </span>
                      </li>
                    </ul>
                    <div className="follow-us">
                      <ul className="d-flex text-right mb-0">
                        <FacebookShareButton
                          url={process.env.REACT_APP_WEB_URL}
                        >
                          <FacebookIcon size={25} round />
                        </FacebookShareButton>

                        <LinkedinShareButton
                          url={process.env.REACT_APP_WEB_URL}
                        >
                          <LinkedinIcon size={25} round />
                        </LinkedinShareButton>

                        <TwitterShareButton
                          url={process.env.REACT_APP_WEB_URL}
                        >
                          <TwitterIcon size={25} round />
                        </TwitterShareButton>

                        <EmailShareButton
                          url={process.env.REACT_APP_WEB_URL}
                        >
                          <EmailIcon size={25} round />
                        </EmailShareButton>
                      </ul>
                    </div>
                  </div>
                  <hr></hr>
                  <h4 className="bold-lg card-title mt-4 mb-2">
                    {Post && Post.title}
                  </h4>
                  <div className="single-blog-content">
                    {/* description */}
                    {parse(cleanHTML)}

                  </div>
                </div>
                <div className="col-lg-3">
                  <BlogCategory />
                </div>
              </div>
            </div>
          </div>
        </div>)}

    </div>
  );
};

export default BlogStructure;
