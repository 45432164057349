import React from "react";
import Pages from "../../pages";
import Footer from "./Footer";
import Header from "./Header";


const Main = () => {
  return (
    <>
        <Header />
        <Pages />
        <Footer />
    </>
  );
};

export default Main;
