
import React from 'react'
import { Link } from 'react-router-dom'
const Errorpage = () => {
    // alert("hello")
  return (
    <>
      <div className="error-page">
        <div className="text-center error-container">
          <div>
            <h2 className="error-text mb-5">Page Not Found</h2>
            <h1 className="large-text">
              4<span className="text-shadow-text">0</span>4
            </h1>
            <div className="mt-5">
              <Link to="/" className="btn btn-primary">
                <span className="btn-index"> GO TO HOME PAGE</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Errorpage