import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { allPostReducer, } from "./reducers/postReducer";
import { categoryListReducer } from "./reducers/categoryReducer";

const reducer = combineReducers({
  adminposts: allPostReducer,
  Allcategory: categoryListReducer
});

const userInfoFromStorage = localStorage.getItem('blog-userInfo')
  ? JSON.parse(localStorage.getItem('blog-userInfo'))
  : null

const userTokenFromStorage = localStorage.getItem('blog-userToken')
  ? localStorage.getItem('blog-userToken')
  : null

const initialState = {
  user: {
    userInfo: userInfoFromStorage,
    token: userTokenFromStorage
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store;