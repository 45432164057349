import React, { useEffect, useState } from 'react'
import BlogCard from './BlogCard'
import { useDispatch, useSelector } from 'react-redux'
import { postListAction } from '../../../src/actions/postAction'
import { categoryListAction } from '../../actions/categoryAction'
import { useSearchParams } from 'react-router-dom'
import Loader from "../loading/Loader";
import BlogCategory from "./BlogCategory"

const BlogList = () => {
  const [searchParams] = useSearchParams()
  const catFilterFromRedirect = searchParams.get('filter')

  const dispatch = useDispatch()
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1)
  const [Categoryfilter, setCategoryfilter] = useState(catFilterFromRedirect)
  const [limit, setLimit] = useState(15)
  
  //get all posts
  const state = useSelector(state => state.adminposts)
  const { post, loading, lastPage, page } = state

  //get category
  const Allcategories = useSelector(state => state.Allcategory)
  // eslint-disable-next-line
  const { category } = Allcategories

  //fetching all categories
  useEffect(() => {
    dispatch(categoryListAction(10000))
    //eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (catFilterFromRedirect)
      setCategoryfilter(catFilterFromRedirect)
    else setCategoryfilter('')
  }, [catFilterFromRedirect])


//fetching all blogs
  useEffect(() => {
    dispatch(postListAction(currentPage, Categoryfilter, limit))
  }, [dispatch, currentPage, Categoryfilter, limit])

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <section className="contact-info-section">
            <div className="container">
              <div className="row flex-direction">
                <div className="col-lg-9">
                  {post.length === 0 ? (
                    <>
                      <h1>No Blogs Available</h1>
                    </>
                  ) : (
                    <div className="row">
                      {post.map((posts) => (
                        <div key={posts._id} className="col-lg-4 col-md-6 mb-4">
                          <BlogCard post={posts} />
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-center">
                        {page < lastPage && (
                          <button
                            className="btn btn-primary"
                            onClick={() => setLimit(limit + 15)}
                          >
                            <span className="btn-index">LOAD MORE</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <BlogCategory />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default BlogList
