import React from 'react'
import { useState } from "react";
import HeroImg from '../../assets/hero-img2.png'
const Herosection = () => {
  const [readMore, setReadMore] = useState(false);
   const extraContent = (
     <div>
       <p className="para-text mb-5 line-ht">
         We have over 20 years of experience in application security. Our
         approach has been to look at application security from two different
         angles i.e; from both the defensive and from the offensive angle.
       </p>
     </div>
   );
   const linkName = readMore ? "Read Less " : "Read More";
  return (
    <div>
      <section className="pt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hero-text home-page-text pt-5">
                <h1 className="text-blue mb-5 line-ht">
                  Secure Your Applications
                </h1>
                <p className="para-text line-ht">
                Get Started with our Application Security Program
                </p>
                <div className="readmore-content">
                  {readMore && extraContent}
                  <div className="mt-5">
                    <span
                      className="read-more-link"
                      onClick={() => {
                        setReadMore(!readMore);
                      }}
                    >
                      <span className="btn">
                        <span className="btn-index"> {linkName}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-img text-center">
                <img alt="" src={HeroImg} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Herosection