import React from "react";
// import ApplicationImg from "../../assets/application.png";
import CloudImg from "../../assets/cloud-security.png";
const TopSection = () => {
  return (
    <div>
      <section className="pt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hero-text pt-5">
                <h1 className="text-blue mb-4 line-ht">Application Security</h1>
                <p className="para-text mb-4 line-ht">
                  For Cloud based & in-house applications
                </p>
                <p className="para-text mb-5 line-ht">
                  We offer end-to-end application security services. First, we
                  learn about the application: the data it manipulates, the
                  business purpose it supports, and the development process
                  used. Then we make suggestions about the best approach. In our
                  opinion, with the right choice of technology and process,
                  businesses can save a lot of time and money and at the same
                  time roll out an application that is secure from ground up.
                </p>
                {/* <p className="link">
                  <a className="btn" href="/">
                    <span className="btn-index"> Read More</span>
                  </a>
                </p> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-img-top text-center pt-5">
                <img alt="" src={CloudImg} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TopSection;
