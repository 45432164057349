import React from 'react'
import CloudImg from "../../assets/cloud-security.png";
const Topsection = () => {
  return (
    <div>
      <section className="pt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hero-text pt-5">
                <h1 className="text-blue mb-5 line-ht">Cloud Security</h1>
                <p className="para-text mb-5 line-ht">
                  We offer end-to-end cloud security. First, we learn about your
                  application, understand the business need and make suggestions
                  about the best approach to take. In our opinion, with the
                  right choice of technology and process, the business can save
                  a lot of time and money to roll out a cloud application.
                </p>
                {/* <p className="link">
                  <a className="btn" href="/">
                    <span className="btn-index"> Read More</span>
                  </a>
                </p> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-img-top text-center pt-5">
                <img alt="" src={CloudImg} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Topsection