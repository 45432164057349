import React from 'react'
import RegulatoryCompliance from "../../view/regulatory-compliance/index";
const index = () => {
  return (
    <div>
      <RegulatoryCompliance />
    </div>
  );
}

export default index