import React from 'react'
import BlogStructure from './BlogStructure';
import EntryHeader from './EntryHeader';

const index = () => {
  return (
    <div>
      <div className="pt-80">
        <EntryHeader/>
        <BlogStructure/>
      </div>
    </div>
  );
}

export default index