import React from 'react'
// import OurSecurity from '../home/OurSecurity'
import ServicesSection from './ServicesSection';
import TopSection from './TopSection'

const index = () => {
  return (
    <div>
      <div className="pt-80">
        <TopSection />
        <ServicesSection/>
        {/* <OurSecurity /> */}
      </div>
    </div>
  );
}

export default index