import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import { SpinnerDotted } from 'spinners-react';

//validation schema
const schema = yup.object().shape({
  fname: yup.string().required("Full Name is required"),
  lname: yup.string().required("Last Name is required"),
  email: yup.string().email().required("Email is required"),
  message: yup.string().required("Message is required"),
  jobRole: yup.string().required("Job Role is required"),
  file: yup.mixed().test("required","Resume is required",value =>{
    return value && value.length
  }),
})

const InfoForm = () => {
  const [userResume, setUserResume] = useState({
    fname: "", lname: "", email: '', DOB: "", jobRole: "", message: ""
  });
  // eslint-disable-next-line 
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  //validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })
  console.log(errors,"errors")

  const validateUser = (e, type) => {
    const typeData = e.target.value;
    if (type === "email") {
      if (typeData.length > 80) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
  };


  //submit resume form
  async function addResume(data) {
    // if(!file){
    //   return setFileError("resume is required")
    // }
    //eslint-disable-next-line
    const { name, email, DOB, jobRole } = userResume;
    const formData = new FormData();
    formData.append('fname', data.fname)
    formData.append('lname', data.lname)
    formData.append('email', data.email)
    formData.append('jobRole', data.jobRole)
    formData.append('message', data.message)
    formData.append('resume', data.file[0])

    const productBody = formData;
    try {
      setLoading(true)
      //eslint-disable-next-line
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/resume/uploadresume`, productBody, {
        headers:
        {
          "Content-Type": "multipart/form-data"
        }
      },
      )
      setUserResume({
        fname: "", lname: "", email: '', jobRole: "", message: ""
      })
      reset();
      toast.success("Form Submitted")
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.response.data.message)
    }
  }

  return (
    <div>
      <section className="pt-80 pb-5">
        <div className="container ">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <form className="info-form mb-5">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        First Name <span className="required-text">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="fname"
                        required
                        {...register("fname")}
                      />
                      <small className="text-danger">
                        {errors.fname?.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Last Name <span className="required-text">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lname"
                        required
                        {...register("lname")}
                      />
                      <small className="text-danger">
                        {errors.lname?.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>
                        Email Address <span className="required-text">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        required
                        {...register("email", {
                          onChange: (e) => validateUser(e, "email"),
                        })}
                      />
                      <small className="text-danger">
                        {errors.email?.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>
                        Job Role <span className="required-text">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="jobRole"
                        required
                        {...register("jobRole")}
                      />
                      <small className="text-danger">
                        {errors.jobRole?.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>
                        Your Message <span className="required-text">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows="3"
                        name="message"
                        defaultValue=""
                        {...register("message")}
                      ></textarea>
                      <small className="text-danger">
                        {errors.message?.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="form-group choose-file ">
                      <label>
                        Resume <span className="required-text">*</span>
                      </label>
                      <br />
                      <label className="resume-label">Select Resume</label>
                      <input
                        name="file"
                        type="file"
                        {...register("file")}
                        className="form-control-file resume-file"
                      />
                        <small className="text-danger">
                        {errors.file?.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {loading ? (
                      <SpinnerDotted />
                    ) : (
                      <button
                        onClick={handleSubmit(addResume)}
                        className="btn btn-primary"
                      >
                        <span className="btn-index"> Submit</span>
                      </button>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <p className="mt-1">
                      <strong>Note:</strong>&nbsp;By clicking Submit, you acknowledge that you have read and agree to Revana Tech’s privacy policy.
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default InfoForm