import React from 'react'
import ContactInfo from './ContactInfo'
import EntryHeader from './EntryHeader'
// import LinkTag from './LinkTag'

const index = () => {
  return (
    <div>
         <div className="pt-80">
             <EntryHeader/>
             {/* <LinkTag/> */}
             <ContactInfo/>
         </div>
    </div>
  )
}

export default index