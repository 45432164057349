import React from 'react'
import Blog from '../../view/blog/index'
const index = () => {
  return (
    <div>
        <div>
            <Blog/>
        </div>
    </div>
  )
}

export default index