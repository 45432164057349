import React from 'react'
import ThirdpartyImg from "../../assets/third-party.png";
const TopSection = () => {
  return (
    <div>
      <section className="pt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hero-text pt-5">
                <h1 className="text-blue mb-5 line-ht">TPRM</h1>
                <p className="para-text mb-5 line-ht">
                  TPRM is very complex as this may involve connectivity, data
                  flow, instalation of software or libraries.
                </p>
                {/* <p className="link">
                  <a className="btn" href="/">
                    <span className="btn-index"> Read More</span>
                  </a>
                </p> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-img-top text-center pt-5">
                <img alt="" src={ThirdpartyImg} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TopSection