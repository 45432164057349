import React from 'react'

const EntryHeader = () => {
  return (
    <div>
      <section className="entry-header">
        <div className="container text-center">
          <h1 className="line-ht">Blog</h1>
          <p className="para-text line-ht">Our Latest Blogs here</p>
        </div>
      </section>
    </div>
  );
}

export default EntryHeader