import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./home/index";
import Contact from "./contact/index";
import Blog from "./blog/index";
import Application from "./application/index";
import CloudSecurity from "./cloud-security/index";
import RegulatoryCompliance from "./regulatory-compliance/index";
import RiskAssessment from './risk-assessment/index'
import SingleBlog from './single-blog/index'
import Career from './career/index'
import Errorpage from "../view/errorpage/Errorpage";


const Pages = () => {
  return (
    <div>
      <Routes >
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route exact path="/blog" element={<Blog/>} />
        <Route exact path="/application-security" element={<Application/>} />
        <Route exact path="/cloud-security" element={<CloudSecurity/>} />
        <Route
          exact
          path="/regulatory-compliance"
          element={<RegulatoryCompliance/>}
        />
        <Route exact path="/risk-assessment" element={<RiskAssessment/>} />
        <Route exact path="/single-blog/:slug" element={<SingleBlog/>} />
        <Route exact path="/career" element={<Career/>} />
        <Route path="*" element={<Errorpage/>}/>
      </Routes>
    </div>
  );
};

export default Pages;
