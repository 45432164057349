import React from 'react'
import Cookies from './Cookies';
import Herosection from './Herosection';
import OurSecurity from './OurSecurity';

const index = () => {
  return (
    <div>
      <div className="pt-80">
        <Herosection />
        <OurSecurity />
        <Cookies/>
      </div>
    </div>
  );
}

export default index