import React from "react";
import Logo from "../../assets/brand-logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";


const Header = () => { 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [isActive, setActive] = useState("home"); 
  return (
    <div>
      <div className="header">
        <div className="container">
          <div className="custom-navbar navbar navbar-expand-lg navbar-light">
            <Link
              className={` ${
                isActive === "home"
                  ? "navbar-brand active-link"
                  : "navbar-brand"
              }`}
              onClick={() => {scrollToTop(); setActive("home")}}
              to="/"
            >
              <img alt="" src={Logo} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="header-menu navbar-nav ml-auto">
                <li className="nav-item active">
                  <Link
                    className={` ${
                      isActive === "home" ? "nav-link active-link" : "nav-link"
                    }`}
                    onClick={() => {scrollToTop(); setActive("home")}}
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className={` ${
                      isActive === "services"
                        ? "nav-link dropdown-toggle active-link"
                        : "nav-link dropdown-toggle"
                    }`}
                    onClick={() => {scrollToTop(); setActive("services")}}
                    id="navbarDropdown"
                    to="/application-security"
                  >
                    Services
                  </Link>
                  <div
                    className="dropdown-menu services-drop-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link
                      className={` ${
                        isActive === "services"
                          ? "dropdown-item link active-link"
                          : "dropdown-item link"
                      }`}
                      onClick={() => {scrollToTop(); setActive("services")}}
                      to="/application-security"
                    >
                      Application Security
                    </Link>
                    <Link
                      className={` ${
                        isActive === "services"
                          ? "dropdown-item link active-link"
                          : "dropdown-item link"
                      }`}
                      onClick={() => {scrollToTop(); setActive("services")}}
                      to="/regulatory-compliance"
                    >
                      GRC
                    </Link>
                    
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    className={` ${
                      isActive === "career"
                        ? "nav-link active-link"
                        : "nav-link"
                    }`}
                    onClick={() => {scrollToTop(); setActive("career")}}
                    to="/career"
                  >
                    Career
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={` ${
                      isActive === "contact"
                        ? "nav-link active-link"
                        : "nav-link"
                    }`}
                    onClick={() => {scrollToTop(); setActive("contact")}}
                    to="/contact"
                  >
                    Contact us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={` ${
                      isActive === "blog" ? "nav-link active-link" : "nav-link"
                    }`}
                    onClick={() => {scrollToTop(); setActive("blog")}}
                    to="/blog"
                  >
                    Blog
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
