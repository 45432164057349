import React from 'react'
import BlogList from './BlogList';
import EntryHeader from './EntryHeader';

const index = () => {
  return (
    <div>
      <div className="pt-80">
          <EntryHeader/>
          <BlogList/>
      </div>
    </div>
  );
}

export default index