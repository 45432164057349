import React from 'react';
import { ToastContainer } from 'react-toastify';
import Main from './components/layout/Main';

function App() {
  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={1700}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <Main/>
    </div>
  );
}

export default App;
