export const ALL_POST_REQUEST = "ALL_POST_REQUEST";
export const ALL_POST_SUCCESS = "ALL_POST_SUCCESS";
export const ALL_POST_FAIL = "ALL_POST_FAIL";

export const SINGLE_POST_REQUEST = "SINGLE_POST_REQUEST";
export const SINGLE_POST_SUCCESS = "SINGLE_POST_SUCCESS";
export const SINGLE_POST_FAIL = "SINGLE_POST_FAIL";

export const ADD_POST_REQUEST = "ADD_POST_REQUEST";
export const ADD_POST_SUCCESS = "ADD_POST_SUCCESS";
export const ADD_POST_FAIL = "ADD_POST_FAIL";

export const UPDATE_POST_REQUEST = "UPDATE_POST_REQUEST";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAIL = "UPDATE_POST_FAIL";

export const USER_LOGOUT = 'USER_LOGOUT'
export const CLEAR_ERRORS = "CLEAR_ERRORS";