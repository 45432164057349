import {
    ALL_CATEGORY_REQUEST,
    ALL_CATEGORY_SUCCESS,
    ALL_CATEGORY_FAIL,
} from "../constants/categoryContant";


export const categoryListReducer = (state={ category:[]},action)=>{
    switch (action.type){
        case  ALL_CATEGORY_REQUEST:
            return{
                loading: true,
                category:[]
            };
        case  ALL_CATEGORY_SUCCESS:
            return{ 
                loading: false,
                category: action.payload.cate, 
            };
        case   ALL_CATEGORY_FAIL:
            return{
                loading: false,
                error: action.payload,  
            };
        default:
            return state;
    }
};


