import React from 'react'
import SingleBlog from '../../view/single-blog/index'
const index = () => {
  return (
    <div>
      <SingleBlog/>
    </div>
  )
}

export default index