import {
    ALL_POST_REQUEST,
    ALL_POST_SUCCESS,
    ALL_POST_FAIL,
    CLEAR_ERRORS
} from "../constants/postConstant";

//post list reducer
export const allPostReducer = (state={ post:[]},action)=>{
    switch (action.type){
        case ALL_POST_REQUEST:
            return{
                loading: true,
                ...state,
            };
        case ALL_POST_SUCCESS:
            return{ 
                loading: false,
                post: [ ...action.payload.UserPost],
                postCount: action.payload.postCount,
                resultPerPage: action.payload.resultPerPage,
                filterPostCount:action.payload.filterPostCount,
                lastPage:action.payload.lastPage,
                page:action.payload.page,
            };
        case ALL_POST_FAIL:
            return{
                loading: false,
                error: action.payload,  
            };
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null,
            };
        default:
            return state;
    }
};