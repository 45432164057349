import React from 'react'

const EntryHeader = () => {
  return (
    <div>
      <section className="entry-header">
        <div className="container text-center">
          <h1 className=" line-ht">Contact Us</h1>
          <p className="para-text line-ht">
            Tell us about your issue so we can get you to the right people, as
            soon as possible.
          </p>
        </div>
      </section>
    </div>
  );
}

export default EntryHeader