import React from 'react'
import RiskAssessment from '../../view/risk-assessment/index'
const index = () => {
  return (
    <div>
        <RiskAssessment/>
    </div>
  )
}

export default index