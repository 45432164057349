import React from "react";
import DataClassification from "../../assets/data-classifcation.png";
import ControlGap from "../../assets/control-gap.png";
import ReducedCost from "../../assets/reduced-cost.png";

const ServicesSection = () => {
  return (
    <div>
      <section className="security-section">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-4 mb-4">
              <div className="card service-card">
                <div className="card-body">
                  <div className="icon-img mb-4 ">
                    <img alt="" src={DataClassification} />
                  </div>
                  <h5 className="card-title warn-text uppercase">
                    Data Classifcation
                  </h5>
                  <p className="card-text">
                    We will work with your teams to set up a simple and mature
                    process from the very beginning either through training or
                    working alongside your application development teams. We can
                    help in setting up the process for agile and any other
                    methodologies that the teams might be comfortable adapting.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card service-card">
                <div className="card-body">
                  <div className="icon-img mb-4 ">
                    <img alt="" src={ControlGap} />
                  </div>
                  <h5 className="card-title warn-text uppercase">
                    Control Gap
                  </h5>
                  <p className="card-text">
                    Choice of the right technology stack will help expedite the
                    return on the investment (ROI). The right choice of
                    technology stack can also help seamlessly integrate security
                    into the application and its architecture. We have
                    experience suggesting the technology stack and security
                    solution patterns that can scale for complex architectures.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card service-card">
                <div className="card-body">
                  <div className="icon-img mb-4 ">
                    <img alt="" src={ReducedCost} />
                  </div>
                  <h5 className="card-title warn-text uppercase">
                    Reduced Cost
                  </h5>
                  <p className="card-text">
                    Having a team member in a development team, who champions
                    application security, can help the development team think
                    about security early in the project life cycle. We have
                    experience training developers to be security champions, who
                    can bridge the gap between enterprise application security
                    team and help adapt security early agile process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesSection;
