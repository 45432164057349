import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import { SpinnerDotted } from 'spinners-react';


//validation schema
const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email().required("Email is required"),
  message: yup.string().required("Message is required"),
})

const ContactForm = () => {
  // eslint-disable-next-line
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  //validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const validateUser = (e, type) => {
    const typeData = e.target.value;
    if (type === "email") {
      if (typeData.length > 80) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
  };

  //submit contact form
  const contactSubmit = async (data) => {
    try {
      setLoading(true)
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/contact`,
        {
          name: data.name,
          email: data.email,
          message: data.message,
        }
      );
      toast.success("Thank You for contacting us")
      reset();
      setLoading(false)
    } catch (error) {
      toast.error(error.response.data.message)
    }
  };


  return (
    <div>
      <form className="contact-form">
        <div className="form-group">
          <label>
            Name <span className="required-text">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="name"
            {...register("name")}
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="form-group">
          <label>
            Email Address <span className="required-text">*</span>
          </label>
          <input
            type="email"
            className="form-control"
            name="email"
            {...register("email", {
              onChange: (e) => validateUser(e, "email"),
            })}
          />
          <small className="text-danger">{errors.email?.message}</small>
        </div>
        <div className="form-group">
          <label>
            Your Message <span className="required-text">*</span>
          </label>
          <textarea
            className="form-control"
            rows="3"
            name="message"
            defaultValue=""
            {...register("message")}
          ></textarea>
          <small className="text-danger">{errors.message?.message}</small>
        </div>
        {loading ? (
          <SpinnerDotted />
        ) : (
          <button
            onClick={handleSubmit(contactSubmit)}
            className="btn btn-primary"
          >
            <span className="btn-index"> Submit</span>
          </button>
        )}
      </form>

    </div>
  );
}

export default ContactForm