import React from 'react'
import EntryHeader from './EntryHeader';
import InfoForm from './InfoForm';

const index = () => {
  return (
    <div>
      <div className="pt-80">
          <EntryHeader/>
        <InfoForm/>
      </div>
    </div>
  );
}

export default index