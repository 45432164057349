import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "../ContactForm";

const Footer = () => {
  const scrollToTop = ()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  }

  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-5">
              <div className="pr-5">
                <h5 className="footer-heading mb-5">Contact us</h5>
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className="pl-5">
                <h5 className="footer-heading  mb-5">Services</h5>
                <ul className="footer-menu-list">
                  <li>
                    <Link to="/application-security" onClick={scrollToTop}>
                      Application Security
                    </Link>
                  </li>
                  <li>
                    <Link to="/regulatory-compliance" onClick={scrollToTop}>
                      GRC
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className="pl-5">
                <h5 className="footer-heading  mb-5">Blog</h5>
                <ul className="footer-menu-list">
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      {" "}
                      Announcements
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      Company &amp; Culture
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      Points of View
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      Products and Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      Partners
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 mb-5">
              <div>
                <h5 className="footer-heading  mb-5">Address</h5>
                <ul className="footer-menu-list">
                  <li>
                    <div className="d-flex">
                      <div className="mr-2">
                        <i
                          className="fa fa-envelope-open-o"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <p className="mb-0">
                        <a href="mailto:support@revanatech.com">
                          support@revanatech.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <div className="mr-2">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                      </div>
                      <p className="mb-0">
                        <a href="tel:6097510502">609-751-0502</a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5 copyright-row">
            <div className="col-lg-6">
              <p className="uppercase copyright-text">
                Copyright ©{new Date().getUTCFullYear()} REVANATECH. All rights
                reserved.
              </p>
            </div>
            <div className="col-lg-6">
              <ul className="socail-links text-right mb-0">
              <li>
                  <Link to="/" onClick={scrollToTop}>
                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/" onClick={scrollToTop}>
                    <i className="fa fa-facebook-square" aria-hidden="true"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/" onClick={scrollToTop}>
                    <i className="fa fa-twitter-square" aria-hidden="true"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/" onClick={scrollToTop}>
                    <i
                      className="fa fa-google-plus-square"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
