import React from 'react'
import CloudSecurity from '../../view/CloudSecurity/index'

const index = () => {
  return (
    <div>
        <CloudSecurity/>
    </div>
  )
}

export default index