import React from 'react'
import ContactForm from "../../components/ContactForm";

const ContactInfo = () => {
  return (
    <div>
      <section className="contact-info-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-5">
              <div>
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-6 mb-5">
              <div>
                <div className="contact-box text-center">
                  <div className="icon-box">
                    <i className="fa fa-envelope-open-o" aria-hidden="true"></i>
                  </div>
                  <p className="mb-0">
                    <a href="mailto:support@revanatech.com">
                      support@revanatech.com
                    </a>
                  </p>
                </div>
                <div className="contact-box text-center">
                  <div className="icon-box">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                  </div>
                  <p className="mb-0">
                    <a href="tel:6097510502">609-751-0502</a>
                  </p>
                </div>
                {/* <div className="contact-box text-center">
                  <div className="icon-box">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </div>
                  <p className="mb-0 text-green">
                    834 N. Lafayette Drive Manitowoc, WI 54220
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactInfo