import React from "react";
import CookieConsent from "react-cookie-consent";

const Cookies = () => {
  return (
    <div>
      <CookieConsent
        buttonText="Confirm"
        expires={365}
        // cookieSecurity={true}
        extraCookieOptions={{ Secure : true}}
        sameSite="strict"

      >
        This website uses cookies to ensure you get the best experience on our
        website.
      </CookieConsent>
    </div>
  );
};

export default Cookies;
