import React from "react";
import Process from '../../assets/process.png'
import Technology from "../../assets/technology.png";
import Grow from "../../assets/grow.png";

const ServicesSection = () => {
  return (
    <div>
      <section className="security-section">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-4 mb-4">
              <div className="card service-card">
                <div className="card-body">
                  <div className="icon-img mb-4 ">
                    <img alt="" src={Process} />
                  </div>
                  <h5 className="card-title warn-text uppercase">Process</h5>
                  <p className="card-text">
                    We will work with your teams to set up a simple and mature
                    process from the very beginning either through training or
                    working alongside your application development teams. We can
                    help in setting up the process for agile and any other
                    methodologies that the teams might be comfortable adapting.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card service-card">
                <div className="card-body">
                  <div className="icon-img mb-4 ">
                    <img alt="" src={Technology} />
                  </div>
                  <h5 className="card-title warn-text uppercase">Technology</h5>
                  <p className="card-text">
                    Sample text. Click to select the text box. Click again or
                    double click to start editing the text.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card service-card">
                <div className="card-body">
                  <div className="icon-img mb-4 ">
                    <img alt="" src={Grow} />
                  </div>
                  <h5 className="card-title warn-text uppercase">Grow it</h5>
                  <p className="card-text">
                    Sample text. Click to select the text box. Click again or
                    double click to start editing the text.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesSection;
