import {
    ALL_POST_REQUEST,
    ALL_POST_SUCCESS,
    ALL_POST_FAIL,
} from "../constants/postConstant";
import axios from "axios";

 // Get all post
 export const postListAction = (currentPage=1,Categoryfilter,limit) => async (dispatch)=>  {
    try{
      dispatch({ type: ALL_POST_REQUEST });

      let link=`${process.env.REACT_APP_API_BASE_URL}/post/getposts?&page=${currentPage}&limit=${limit}`;
      
      if(Categoryfilter){
        link=`${process.env.REACT_APP_API_BASE_URL}/post/getposts?&category=${Categoryfilter}&page=${currentPage}&limit=${limit}`
      }
      
      const { data } = await axios.get(link);
      dispatch({ 
          type: ALL_POST_SUCCESS, 
          payload: data,
        });
    }catch(error){
       dispatch({
        type: ALL_POST_FAIL,
        payload: error.response.data.message,
       });
    }
};
