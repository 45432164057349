import React from 'react'
import Contact from '../../view/contact/index'
const index = () => {
  return (
    <div>
        <Contact/>    
    </div>
  )
}

export default index